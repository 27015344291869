<template>
<div>
    <b-jumbotron class="py-4 px-0">
      <b-container fluid>
      <h4>Setări</h4>
      </b-container>
    </b-jumbotron>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-tabs pills active-tab-class="mt-4" active-nav-item-class="bg-dark" nav-wrapper-class="nav-border" style="max-width: 900px">
              <b-tab title="Profil"  active>
                <h6>Setări Profil </h6>
                <hr />

                <b-form-group label-cols="4" label-cols-lg="2" label="Nume:" label-for="input-default">
                  <b-form-input v-model="user.name"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="4" label-cols-lg="2" label="Email:" label-for="input-default">
                  <b-form-input v-model="user.email"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="4" label-cols-lg="2" label="Telefon:" label-for="input-default">
                  <b-form-input v-model="user.phone"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="4" label-cols-lg="2" label="Companie:" label-for="input-default">

                  <div v-if="user.companies" class="company-square my-0 text-left">
                    <b-button size="sm" variant="success" class="mb-2" to="/settings">{{ user.companies[0].name }}</b-button> <br>
                    <span>{{ user.companies[0].address }} </span>
                  </div>
                </b-form-group>

                <b-button @click="updateUser" class="pull-right ml-2 " size="sm" variant="success">Salvează</b-button>
              </b-tab>
              <b-tab title="Setări SIP" >
                <h6>Setări SIP </h6>
                <p>Stabilire conexiune cu centrala telefonică virtuală (SIP).</p>
                <hr />
                    <SipSettings></SipSettings>
                  </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
import config from '@/config'
import SipSettings from '@/components/settings/account/SipSettings'

export default {
  name: 'AccountSettings',
  components: { SipSettings },
  data () {
    return {
      user: {}
    }
  },
  mounted () {
    this.$axios({
      method: 'get',
      url: config.baseApiUrl + '/api/users/' + this.$store.getters['auth/loggedUser'].id
    })
      .then((response) => {
        this.user = response.data
      })
  },
  methods: {
    updateUser () {
      this.$axios({
        method: 'patch',
        url: config.baseApiUrl + '/api/users/' + this.$store.getters['auth/loggedUser'].id,
        data: this.user
      })
        .then((response) => {
          this.$toasted.success('Profil salvat!', {
            duration: 3000
          })
          this.user = response.data
        })
    },
    getUser () {
      this.$axios({
        method: 'get',
        url: config.baseApiUrl + '/api/users/' + this.$store.getters['auth/loggedUser'].id
      })
        .then((response) => {
          this.user = response.data
        })
    }
  }
}
</script>

<style scoped>
  .account-settings .card header {
    font-weight: bold;
    font-size: large;
  }
</style>
